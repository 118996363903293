<template>
  <div class="main-banner-container">
    <div class="grid-container mx-auto">
      <div class="main-banner grid gap-x-4 grid-cols-1 xl:grid-cols-12">
        <div
          class="banner-incentive-container banner-image-container xl:col-span-6 xxl:col-span-7 xl:order-2"
        >
          <picture>
            <source
              :srcset="helperImage.optimise(contentData.foregroundImageDesktop)"
              media="(min-width: 1024px)"
              :width="contentData.foregroundImageDesktop?.width || 'auto'"
              :height="contentData.foregroundImageDesktop?.height || 'auto'"
              fetchpriority="high"
              loading="eager"
            />
            <source
              :srcset="helperImage.optimise(contentData.foregroundImageTablet)"
              media="(min-width: 560px)"
              :width="contentData.foregroundImageTablet?.width || 'auto'"
              :height="contentData.foregroundImageTablet?.height || 'auto'"
              fetchpriority="high"
              loading="eager"
            />
            <img
              :src="helperImage.optimise(contentData.foregroundImageMobile)"
              :alt="contentData.foregroundImageDesktop?.description || 'Banner Image'"
              :width="contentData.foregroundImageMobile?.width || 'auto'"
              :height="contentData.foregroundImageMobile?.height || 'auto'"
              class="banner-image"
              fetchpriority="high"
              loading="eager"
            />
          </picture>

          <CoverIncentive
            v-if="contentData.incentive"
            :content="contentData.incentive"
            :class="contentData.incentiveLocation"
          />
        </div>
        <div
          class="banner-content flex flex-col justify-center gap-4 px-4 py-6 xxl:px-6 xl:col-span-6 xxl:col-span-5"
        >
          <AppointmentSelector
            v-if="contentData.appointmentForm && contentData.type === 'appointment'"
            :content="contentData.appointmentForm"
            :banner-content="contentData.bannerContent"
            :trust-pilot-widget="contentData.trustPilotWidget"
          />
          <MultiStepForm
            v-else-if="contentData.multiStepForm && contentData.type === 'step_banner'"
            :content="contentData.multiStepForm"
            wrapper-class="multistep-quote-container banner"
          />
          <BannerContent
            v-else
            :content="contentData.bannerContent"
            :trust-pilot-widget="contentData.trustPilotWidget"
            :gtm="gtm.heroBannerCTA"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { helperImage } from '#root/utils/helpers'
import getBannerContent from '#root/components/composables/getBannerContent.js'
import MultiStepForm from '#root/components/forms/multi-step/MultiStepForm.vue'
import BannerContent from '#root/components/banners/BannerContent.vue'
import CoverIncentive from '#root/components/pageblocks/CoverIncentive.vue'
import AppointmentSelector from '#root/components/pageblocks/AppointmentSelector.vue'

const props = defineProps({
  content: {
    type: Object,
    required: true
  }
})

const { gtm, contentData } = getBannerContent(props)
</script>

<style lang="scss">
// Container reset
.site-golden {
  .main-banner-container {
    background: $white;
    width: 100%;
    overflow: hidden;

    @include media-query('desktop') {
      border-bottom: 1px solid $gs-300;
    }

    .grid-container {
      max-width: unset;
      width: unset;

      @include media-query('desktop') {
        width: $g-desktop-breakpoint-min-width;
      }

      @include media-query('desktop-large') {
        width: $g-desktop-large-breakpoint-min-width;
      }

      .banner-content {
        padding: $spacing-6 $spacing-4;
        width: 100%;
        margin: 0 auto;

        @include media-query('phablet') {
          max-width: $g-phablet-breakpoint-min-width;
        }

        @include media-query('tablet') {
          max-width: 100%;
          width: $g-tablet-breakpoint-min-width;
        }

        @include media-query('desktop') {
          max-width: unset;
          width: unset;
          padding: 0;
        }
      }

      .main-banner {
        @include media-query('desktop') {
          padding: 0 16px;
        }

        @include media-query('desktop-large') {
          padding: 0 24px;
        }

        .banner-image-container {
          height: 240px;
          position: relative;
          overflow: visible;

          @include media-query('phablet') {
            height: 380px;
          }

          @include media-query('desktop') {
            height: 580px;
            width: auto;
          }

          .banner-image {
            width: 100%;
            object-fit: cover;
            object-position: top;
            display: block;
            height: 100%;

            @include media-query('tablet') {
              object-position: right;
              object-fit: none;
            }

            @include media-query('desktop') {
              height: 580px;
              object-fit: cover;
              width: auto;
              position: absolute;
              top: 0;
              left: -172px;
            }

            @include media-query('desktop-large') {
              left: -52px;
            }
          }
        }

        .incentive-container {
          img {
            width: 120px;
            height: 120px;
            position: absolute;
            right: 16px;
            bottom: 24px;

            @include media-query('phablet') {
              width: 176px;
              height: 176px;
            }

            @include media-query('desktop') {
              right: 0;
            }
          }
        }
      }
    }

    .links {
      .link-element,
      .cta-btn {
        width: 100%;
      }

      @include media-query('phablet') {
        .link-element,
        .cta-btn {
          width: unset;
        }
      }
    }
  }
}
</style>
